.layout {
  min-width: 100vw;
  min-height: 100vh;
  background-size: cover;

  .wrapper {
    min-height: calc(100vh - 64px);
    //width: 100%;
    min-width: 240px;
    max-width: 400px;
    margin: auto;
    padding: 32px 20px;

    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;

    .app {
      margin-top: 48px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      .logo {
        img {
          width: 64px;
          height: 64px;
        }
      }
      .name {
        font-size: 24px;
        font-weight: bold;
        a {
          color: var(--color-white);
          text-decoration: none;
        }
      }
    }
    .title {
      font-size: 32px;
      font-stretch: expanded;
      font-weight: bold;
      text-align: center;
    }
    .content {
      flex: 1;
      width: 100%;
      .fill {
        padding: 24px;
        background-color: var(--color-black);
        border-radius: 16px;
      }
    }
    .cd2c {
      img {
        width: 46px;
        height: 10px;
      }
    }

  }

}

