.card {

  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px;
  border-radius: 16px;
  border-left: 0px solid var(--color-solid);
  &.small {
    padding: 12px 8px;
    border-radius: 8px;
    border-left: 2px solid var(--color-solid);
    .title {
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      font-size: 12px;
      //text-overflow: ellipsis;
    }
  }

  color: var(--color-white);
  background-color: var(--color-solid);
  transition: all 0.5s ease-out;
  cursor: pointer;

  z-index: 10;
  
  &.dragging {
  }

  &:hover {
    background-color: #303030;
  }

  .title {
    font-weight: 600;
    transition: all 0.5s ease-out;
  }

  .tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

}