.layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  .content {
    overflow: auto;
    display: flex;
    flex: 1 1;
    padding: 0 40px 40px 40px;
    @media screen and (max-width: 1200px) {
      padding: 0 20px 20px 20px;
    }

  }
}