.wrapper {
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
  border-radius: 16px;
  background-color: var(--color-solid);

  .content {
    max-width: 650px;
    margin: auto;
    padding: 0 24px;
  }

  .top {
    margin-top: 24px;
    display: flex;
    gap: 8px;
    align-items: center;
    .name {
      flex: 1;
      font-weight: bold;
    }
    .control {
      display: flex;
      gap: 8px;
    }
  }
  .title {
    font-size: 32px;
    font-weight: bold;
    font-stretch: expanded;
  }

}

.divider {
  max-width: 650px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }

  border-bottom: 1px solid var(--color-border);
  margin: 8px auto;
  &.big {
    margin: 24px auto;
  }
  &.first {
    margin: 24px auto 8px auto;
  }
}


.params {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
}

.label {
  color: var(--color-status-new);
  align-self: center;
}