.cover {
  min-width: 100vw;
  min-height: 100vh;
  //max-height: 800px;
  //@media screen and (max-width: 768px) {
  //  max-height: unset;
  //}
  background-size: cover;
  background-position: center center;
  .wrapper {

    min-height: calc(100vh - 72px);
    //width: 100%;
    min-width: 240px;
    max-width: 600px;
    margin: auto;
    padding: 40px 20px 32px 20px;

    display: flex;
    flex-direction: column;
    gap: 80px;
    align-items: center;
    text-align: center;
    justify-content: space-between;

    .content {

      display: flex;
      flex-direction: column;
      gap: 40px;
      h3 {
        margin: unset;
      }

      .app {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        .logo {
          img {
            width: 64px;
            height: 64px;
          }
        }
        .name {
          font-size: 24px;
          font-weight: bold;
          a {
            color: var(--color-white);
            text-decoration: none;
          }
        }
      }
    }

  }
}

.blocks {
  min-width: 240px;
  max-width: 600px;
  margin: auto;
  padding: 80px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  text-align: center;
  h3 {
    margin: unset;
  }

  .block {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

}

.subheadline {
  font-size: 24px;
}

.button {
  font-size: 24px;
  height: 60px;
  padding: 0 32px !important;
}
.icon {
  font-size: 16px !important;
  //position: relative;
  //top: 2px;
}

.fares {

  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  .fare {
    width: calc((100% - 24px - 64px) / 2);
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    text-align: left;
    padding: 16px;
    background-color: var(--color-solid);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    .duration {
      font-size: 24px;
    }
    .old {
      text-decoration: line-through;
    }
    .amount {
      font-size: 24px;
      font-weight: bold;
    }

    opacity: 0.9;
    &:hover {opacity: 1;}
    transition: all 0.3s ease-in-out;

    &.disabled {
      opacity: 0.3;
    }

  }
}

.control {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .dzen {
    font-size: 18px;
    font-weight: bold;
    img {
      margin-right: 4px;
      position: relative;
      top: 4px;
    }
  }
  a {
    text-decoration: none;
    color: var(--color-white);
  }
}