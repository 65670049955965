.text {
  margin-bottom: 24px;
}

.warning {
  padding: 16px;
  border: 1px solid var(--color-status-progress);
  color: var(--color-status-progress);
  border-radius: 8px;
  margin-bottom: 24px;

  .icon {
    font-size: 32px;
    margin-bottom: 8px;
  }

}