.page {
  display: flex;
  flex-direction: column;
  gap: 16px;

  div {
    text-align: center;
  }

}

.activateButton {
  display: flex;
  flex-direction: column;
  height: unset;
  .bold {
    font-weight: bold;
  }
}

.errorTitle {
  font-size: 20px;
  color: var(--color-error);
}


.row {
  display: flex;
  gap: 16px;
  align-items: center;
  .number {
    width: 15px;
    color: var(--color-status-new)
  }
  .admin {
    width: 15px;
  }
  .name {
    flex: 1;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .control {
    display: flex;
    gap: 8px;
  }

  &.hided {
    opacity: 0.5;
  }

}

.plus {
  margin-left: 31px;
}