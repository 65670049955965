.wrapper {
  //margin-bottom: 24px;
}

.banner {
  padding: 8px 40px;
  @media screen and (max-width: 768px) {
    padding: 8px 20px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.warning {
    background-color: var(--color-status-progress);
    color: var(--color-black)
  }
  &.error {
    background-color: var(--color-status-approval);
    font-weight: bold;
    color: var(--color-white)
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 24px 40px;
  &.index {padding: 24px 40px 0 40px;}
  @media screen and (max-width: 1200px) {
    padding: 24px 20px;
    &.index {padding: 24px 20px 0 20px;}
  }

  .left {
    display: flex;
    gap: 8px;
    align-items: center;

    .domainButton {
      max-width: 180px;
      @media screen and (max-width: 768px) {
        max-width: 120px;
      }
      span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

  }

  .right {
    display: flex;
    gap: 8px;
    align-items: center;
  }

}