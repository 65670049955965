.reports {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  font-size: 24px;
  font-stretch: expanded;
  font-weight: bold;
  margin-bottom: 24px;
}

.grid {
  display: grid;
  grid-template-columns: min-content min-content 1fr;
  .user {
    padding: 0 4px;
    height: 32px;
    display: flex;
    gap: 4px;
    align-items: center;
  }
  .time {
    padding: 0 4px;
    line-height: 32px;
    text-align: right;
  }
  .comment {
    padding: 0 4px;
    line-height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

  }
}