.board {
  margin-top: 24px;
  flex: 1 1;
  display: flex;
  gap: 40px;
  @media screen and (max-width: 1200px) {
    gap: 20px;
  }
  transition: all 0.5s ease-out;;

  .column {
    flex: 1 1;
    min-width: 160px;
    &.small {
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;
      .timer {
        font-weight: 400;
        font-size: 12px;
      }
    }

    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 16px;
    @media screen and (max-width: 1200px) {
      gap: 8px;
    }
    border-radius: 16px;
    position: relative;
    transition: all 0.5s ease-out;;

    .dragArea {
      display: block;
      position: absolute;
      left: -4px;
      top: -4px;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border-radius: 20px;
      background: none;
      border: 1px dashed var(--color-black);
      transition: all 0.3s ease-in-out;
      z-index: 0;

      &.dragged {
        background-color: var(--color-secondary-background);
        border: 1px dashed var(--color-secondary);
      }

      &.wipLimit {
        background-color: unset;
        border: 1px dashed var(--color-status-approval);
        //cursor: no-drop !important;
      }

    }

    .focus {
      display: block;
      position: absolute;
      left: -20px;
      top: -20px;
      width: calc(100% + 40px);
      height: calc(100% + 40px);
      border-radius: 32px;
      @media screen and (max-width: 1200px) {
        left: -10px;
        top: -10px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        border-radius: 24px;
        &.small {
          border-radius: 16px;
        }
      }
      border: 1px solid var(--color-border);
    }

    .marker {
      height: 8px;
      border-radius: 4px;
    }
    .title {
      display: flex;
      justify-content: space-between;
      padding: 7px 16px;
      border-radius: 16px;
    }

    .timer {
      font-size: 32px;
      font-weight: 100;
      text-align: center;
      transition: all 0.5s ease-out;;
    }

  }

}