@import "./assets/font/stylesheet.css";
// COLORS

:root {

  --font-family: 'SF Pro', sans-serif;

  --font-body: 15px var(--font-family);
  --font-title: 36px var(--font-family);

  --color-black: #000000;
  --color-solid: #202020;
  --color-border: #424242;
  --color-white: #FFFFFF;
  --color-primary: #7037CD;
  --color-secondary: #68B2F8;
  --color-error: #DC4446;
  --color-secondary-background: rgba(104, 178, 248, 0.1);
  --color-status-new: #9B9B9B;
  --color-status-progress: #F8E71C;
  --color-status-approval: #E8178A;
  --color-status-done: #7ED321;
  --color-status-archive: #68B2F8;

}

.dissolved {
  animation: show-element 1 300ms ease-out;
}

@keyframes show-element {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

//Fonts

body, html {
  background-color: var(--color-black);
  font: var(--font-body);
  font-stretch: normal;
  font-weight: normal;
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  margin: 0;
  padding: 0;
}

h3 {
  font: var(--font-title);
  font-stretch: expanded;
  font-weight: bold;
}

