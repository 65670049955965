.wrapper {
  flex: 1 1;
  display: flex;
  gap: 40px;

  .left {
    flex: 1 1;
  }
  .page {
    flex: 3 1;
    background-color: var(--color-solid);
    border-radius: 16px;
    padding: 32px;

    display: flex;
    gap: 32px;
    .content {
      flex: 1 1;
    }

  }
  .right {
    flex: 1 1;
  };

}

.name {
  font-size: 36px;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
  font-stretch: expanded;
  font-weight: 700;
}

.select {
  width: 100%;
  div {
    padding: 0 !important;
  }
}

.editor {
  width: 100%;
  @media screen and (max-width: 768px) {
    max-width: calc(100% - 48px);
    margin: auto;
  }
}